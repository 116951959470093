<!-- customization start -->
<template>
  <section class="entire-screen columns">
    <div class="login column is-flex 
      is-flex-direction-column 
      is-justify-content-center
      is-align-items-center"
    >
        <a class="sign-in-button" href="/gitlab/login">
          <img src="https://scm.meginfo.com/uploads/-/system/appearance/header_logo/1/LOGO-Light%E5%89%AF%E6%9C%AC.png" >
          Sign in with Meginfo Gitlab
        </a>
      </div>
   </section>
</template>

<script>

export default {
  components:{
  },

  mounted(){
  }
}
</script>

<style lang="scss" scoped>
  .sign-in-button {
    height: 46px;
    width: 320px;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #1a73e8;
    border: 0.5px solid #1a73e8;
    border-radius: 23px;
    img {
      width: 40px;
      margin-right: 10px;
    }
  }
</style>
<!-- customization end -->
