<template>
  <section class="entire-screen columns">
    <div class="login column is-flex 
    is-flex-direction-column 
    is-justify-content-center
    is-align-items-center">

      <login-form/>

    </div>
    
    <!-- customization start -->
    <!-- <div class="column infoSection is-flex 
    is-flex-direction-row 
    is-justify-content-center
    is-align-items-center">

      <info/>
      
    </div> -->
    <!-- customization end -->
   </section>
</template>

<script>

import LoginForm from './components/LoginForm'
/* customization start */
// import Info from './components/Info'
/* customization end */


export default {
  components:{
    LoginForm,
    /* customization start */
    // Info,
    /* customization end */
  },

  mounted(){
    /* customization start */
    // this.$router.push('/workflows');
    /* customization end */
  }
}
</script>

<style lang="scss" scoped>

  .login{
    border-right: thin solid black;
  }

  .column{
    padding:50px
  }

  .infoSection{
    background-color: #f1f1f1;;
  }

</style>
